@charset "UTF-8";
/*!*** Base Rules *****/
/*!
	 * html5doctor.com Reset Stylesheet v1.6.1 (http://html5doctor.com/html-5-reset-stylesheet/)
	 * Richard Clark (http://richclarkdesign.com)
	 * http://cssreset.com
	 */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/*!*** Base Rules(Custom) *****/
*, *:before, *:after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: "游ゴシック" , "Yu Gothic" , "游ゴシック体" , "YuGothic" , "ヒラギノ角ゴ Pro W3" , "Hiragino Kaku Gothic Pro" , "Meiryo UI" , "メイリオ" , Meiryo , "ＭＳ Ｐゴシック" , "MS PGothic" , sans-serif;
  color: #555;
}

/*!*** Layout Rules *****/
.test {
  display: flex;
  transform: translate(50px, 50px);
  border-radius: 3px;
}

article h1 {
  text-align: center;
  color: #f00;
  margin: 1em auto;
  width: 300px;
  padding: 1em;
  border: 1px solid #0F0;
}

/*!*** Module Rules *****/
.header hr {
  margin-top: 0;
  margin-bottom: 0;
}

.header .header-inner {
  width: 1110px;
  height: 85px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .header-inner .title-main {
  font-size: 3.6rem;
  letter-spacing: 0.1em;
}

.header .main-visual {
  text-align: center;
  margin-top: 2em;
  margin-bottom: 2em;
}

.main {
  width: 1110px;
  margin-right: auto;
  margin-left: auto;
}

.title-l {
  text-align: center;
  font-size: 3.0rem;
  border-bottom: 1px solid #a0a0a0;
  padding-bottom: 0.5em;
  letter-spacing: 0.5em;
  padding-left: 0.5em;
  margin-bottom: 1em;
}

.title-s {
  text-align: center;
  font-size: 2.4rem;
  font-weight: bold;
  margin-bottom: 1em;
  letter-spacing: 0.1em;
}

.title-s a {
  text-decoration: none;
  color: #555;
  transition: 0.2s;
}

.title-s a:hover {
  opacity: 0.8;
}

.section-wrap {
  margin-bottom: 90px;
}

.gray-box {
  width: 760px;
  margin-right: auto;
  margin-left: auto;
  background-color: #EEE;
  padding: 1em;
}

.bar-right {
  border-right: 1px solid #a0a0a0;
}

.link-box {
  position: relative;
}

.link-box a {
  display: block;
  transition: .2s all;
  width: 350px;
  height: 134px;
  background-color: #ddd;
  text-align: center;
  font-size: 2.4rem;
  font-weight: bold;
  color: #FFF;
  text-decoration: none;
  line-height: 1.5;
  position: relative;
  letter-spacing: 0.1em;
}

.link-box a:hover {
  opacity: .8;
}

.link-box .link-inner {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  width: auto;
  height: auto;
  font-size: 1.8rem;
  font-weight: normal;
}

.link-box .link-inner.inner-color-1 {
  width: 150px;
  top: 80px;
  margin-left: -75px;
  background-color: #ac8809;
}

.link-box .link-inner.inner-color-2 {
  width: 260px;
  top: 80px;
  margin-left: -130px;
  background-color: #2d618a;
}

.link-box .color-1 {
  background-color: #f9be00;
  padding-top: 36px;
}

.link-box .color-2 {
  background-color: #4593d1;
  padding-top: 36px;
}

.link-box .color-3 {
  background-color: #f9be00;
  padding-top: 36px;
}

.link-box .color-4 {
  background-color: #d3b047;
  padding-top: 47px;
  font-size: 2.0rem;
}

.link-box .color-5 {
  background-color: #4593d1;
  padding-top: 36px;
}

.link-box .color-6 {
  background-color: #d3b047;
  padding-top: 47px;
  font-size: 2.0rem;
}

.link-box .color-7 {
  background-color: #4593d1;
  padding-top: 25px;
  font-size: 2.0rem;
}

.link-box .color-7 span {
  display: inline-block;
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 1.2;
  padding-top: 0.5em;
}

.link-box .color-8 {
  background-color: #4593d1;
  padding-top: 25px;
  font-size: 2.0rem;
}

.link-box .color-8 span {
  display: inline-block;
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 1.2;
  padding-top: 0.5em;
}

.title-gray {
  width: 540px;
  background-color: #a0a0a0;
  color: #FFF;
  padding: 0.5em;
  font-size: 2.4rem;
  letter-spacing: 0.1em;
  margin-bottom: 0.5em;
}

.link-list {
  padding-left: 2em;
}

.link-list > li {
  margin-bottom: 1em;
}

.flex-wrap {
  display: flex;
  justify-content: space-between;
}

.w1110 {
  width: 760px;
  margin-right: auto;
  margin-left: auto;
  padding: 1em;
}

.link-wrap {
  display: flex;
  justify-content: space-between;
}

.footer {
  background-color: #362e2b;
  color: #FFF;
  text-align: center;
  padding-top: 2em;
  padding-bottom: 2em;
  margin-top: 4em;
  padding-right: 2em;
  padding-left: 2em;
}
