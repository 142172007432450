
.header{
	hr{
		margin-top: 0;
		margin-bottom: 0;
	}

	.header-inner{

		width: 1110px;
		height: 85px;
		margin-right: auto;
		margin-left: auto;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.title-main{
			font-size: 3.6rem;
			letter-spacing: 0.1em;
		}
	}


	.main-visual{
		text-align: center;
		margin-top: 2em;
		margin-bottom: 2em;
	}

}




.main{
	width: 1110px;
	margin-right: auto;
	margin-left: auto;
}

.title-l{
	text-align: center;
	font-size: 3.0rem;
	border-bottom: 1px solid #a0a0a0;
	padding-bottom: 0.5em;
	letter-spacing: 0.5em;
	padding-left: 0.5em;
	margin-bottom: 1em;
}

.title-s{
	text-align: center;
	font-size: 2.4rem;
	font-weight: bold;
	margin-bottom: 1em;
	letter-spacing: 0.1em;
	a{
		text-decoration: none;
		color: #555;
		transition:0.2s;
	}
	a:hover{
		opacity:0.8;
	}
}

.section-wrap{
	margin-bottom: 90px;
}

.gray-box{
	width: 760px;
	margin-right: auto;
	margin-left: auto;
	background-color: #EEE;
	padding: 1em;
}

.bar-right{
	border-right: 1px solid #a0a0a0;
}

.link-box{
	position: relative;
	a{
		display: block;
		transition: .2s all;
		width: 350px;
		height: 134px;
		background-color: #ddd;
		text-align: center;
		font-size: 2.4rem;
		font-weight: bold;
		color: #FFF;
		text-decoration: none;
		line-height: 1.5;
		position: relative;
		letter-spacing: 0.1em;
	}
	a:hover{
		opacity:.8;
	}

	.link-inner{
		position: absolute;
		top: 0;
		left: 50%;
		z-index: 1;
		width: auto;
		height: auto;
		font-size: 1.8rem;
		font-weight: normal;
		&.inner-color-1{
			width: 150px;
			top: 80px;
			margin-left: -75px;
			background-color: #ac8809;
		}
		&.inner-color-2{
			width: 260px;
			top: 80px;
			margin-left: -130px;
			background-color: #2d618a;
		}
	}


	.color-1{
		background-color: #f9be00;
		padding-top: 36px;
	}

	.color-2{
		background-color: #4593d1;
		padding-top: 36px;
	}

	.color-3{
		background-color: #f9be00;
		padding-top: 36px;
	}

	.color-4{
		background-color: #d3b047;
		padding-top: 47px;
		font-size: 2.0rem;
	}

	.color-5{
		background-color: #4593d1;
		padding-top: 36px;
	}

	.color-6{
		background-color: #d3b047;
		padding-top: 47px;
		font-size: 2.0rem;
	}

	.color-7{
		background-color: #4593d1;
		padding-top: 25px;
		font-size: 2.0rem;
		span{
			display: inline-block;
			font-size: 1.8rem;
			font-weight: normal;
			line-height: 1.2;
			padding-top: 0.5em;
		}
	}

	.color-8{
		background-color: #4593d1;
		padding-top: 25px;
		font-size: 2.0rem;
		span{
			display: inline-block;
			font-size: 1.8rem;
			font-weight: normal;
			line-height: 1.2;
			padding-top: 0.5em;
		}
	}

}


.title-gray{
	width: 540px;
	background-color: #a0a0a0;
	color: #FFF;
	padding: 0.5em;
	font-size: 2.4rem;
	letter-spacing: 0.1em;
	margin-bottom: 0.5em;
}

.link-list{
	padding-left: 2em;
	>li{
		margin-bottom: 1em;
	}
}


.flex-wrap{
	display: flex;
	justify-content: space-between;
}

.w1110{
	width: 760px;
	margin-right: auto;
	margin-left: auto;
	padding: 1em;
}

.link-wrap{
	display: flex;
	justify-content: space-between;
}


.footer{
	background-color: #362e2b;
	color: #FFF;
	text-align: center;
	padding-top: 2em;
	padding-bottom: 2em;
	margin-top: 4em;
	padding-right: 2em;
	padding-left: 2em;
}