*, *:before, *:after{
	box-sizing: border-box;
}

html{
	font-size: 62.5%;
}

body {
  font-size: 1.6rem;
	font-family:"游ゴシック" , "Yu Gothic" , "游ゴシック体" , "YuGothic" , "ヒラギノ角ゴ Pro W3" , "Hiragino Kaku Gothic Pro" , "Meiryo UI" , "メイリオ" , Meiryo , "ＭＳ Ｐゴシック" , "MS PGothic" , sans-serif;
	color: #555;
}
